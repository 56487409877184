<template>

  <div class="flex justify-end w-full my-5" v-if="errorsList.items.length > 0">
    <!--- validation is displayed here, userform is name of the form--->
  <div class="valiadtion-popup py-10">
    <div class="w-full flex">
      <img :src="warningIcon"  class=" warning-icon"/>

      <p class="mb-0 warningText text-base">Please populate all mandatory fields before proceeding.</p>
  </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Popup",
  props: {
    formName: {
      type: String,
      required: true
    },
    errorsList: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      warningIcon: require("@/assets/images/icons/alert-circle.svg")
    }
  },
  methods:{
  getErrorFieldId(id){
    return '#'+id
  }
  }

}
</script>

<style>
.valiadtion-popup{
  width: 520px !important;
  background: #FEF7F7;
  border: 1px solid #EB5757;
  border-radius: 8px;
}
.warning-icon{
  height: 25px;
  width: 25px;
  @apply mx-2
}

.warningText,.warning-icon{
  color: #EB5757;
}

</style>
