import {EventBus} from "@/mixins/event-bus";

export const leavePopUpMixin = {
  data() {
    return {}
  },
  created: function() {
    localStorage.setItem("formFieldUpdated", "no");
    localStorage.setItem("redirectTo", undefined);
  },
  mounted: function() {
    localStorage.setItem("formFieldUpdated", "no");
    localStorage.setItem("redirectTo", undefined);
  },
  methods: {
    popActions(event) {
      switch (event) {
        case "leave":
          EventBus.$emit("leavePopUpTrigger", "yes");
          this.$nextTick(() => {
            this.nextObj()
          });
          break;
        case "close":
          EventBus.$emit("leavePopUpTrigger", "no");
          this.popupActive = false;
          break;
        default:
          EventBus.$emit("leavePopUpTrigger", "no");
          this.popupActive = false;
      }
    },
  }
};
